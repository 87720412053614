.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.login-message {
    font-size: 2em;
    color: #333;
    margin-bottom: 1em;
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.login-form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
}