.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.active {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    width: 90%;
    max-width: 500px;
    transform: scale(0.5);
    max-height: 80vh;
    overflow-y: auto;
    word-wrap: break-word;
    transition: transform 0.3s ease;
}

.full-text {
    text-align: justify;
}

.modal-overlay.active .modal-content {
    transform: scale(1);
}

.modal-character-image {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
    aspect-ratio: 8/9;
    object-fit: cover;
    object-position: top;
}

.modal-content h2,
.modal-content h3 {
    margin: 0;
    font-size: 200%;
    padding: 10px 0;
}

.modal-content p {
    font-size: 150%;
    padding: 10px 0;
    margin: 0;
    margin-bottom: 1em;
}

.modal-content button {
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    font-size: 16px;
}

.modal-content button:hover {
    background-color: #555;
}