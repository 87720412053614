.photo-container {
    position: relative;
}

.caption-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0.3%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    font-size: 120%;
    padding: 15px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.photo-container:hover .caption-overlay {
    opacity: 1;
}