.character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-left: 14%;
    margin-right: 14%;
}

@media (max-width: 700px) {
    .character-grid {
        grid-template-columns:auto;
        margin-left: 5%;
        margin-right: 5%;
    }
}

.character-box {
    cursor: pointer;
    border: 2px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.character-box:hover {
    transform: scale(1.05); /* Slightly enlarges the box on hover */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Adds shadow effect on hover */
}

.character-image {
    width: 100%;
    aspect-ratio: 8/9;
    object-fit: cover;
    object-position: top;
    transition: opacity 0.3s ease; /* Smooth transition for image hover */
}

.character-image:hover {
    opacity: 0.9; /* Slightly dims the image on hover */
}

.character-info h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 3%;
}

.character-info p {
    font-size: 1.5em;
    margin-top: 0;
}