.App {
  text-align: center;
  background: url('./assets/textures/old-paper-texture.jpg') no-repeat center center fixed;
  background-size: cover;
  color: #3e2723;
  font-family: 'Perpetua', 'Times New Roman', serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  flex-grow: 1;
  padding: 2rem;
}

h1 {
  font-size: 3rem;
  color: #4e342e;
  margin-bottom: 1rem;
  text-shadow: 1px 1px #5d4037;
}

/* You might want borders that look like old frames around sections */
.border-frame {
  border: 1px solid #6d4c41;
  padding: 1rem;
  margin-top: 2rem;
  background: rgba(255,255,255,0.8);
}

.logout-container {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}

.logout-button {
  padding: 10px 15px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #ff7875;
}