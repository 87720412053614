.tab-nav {
    margin-bottom: 1rem;
  }
  
  .tab-nav button {
    background: none;
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }
  
  .tab-nav button:hover,
  .tab-nav button:focus {
    color: #007bff;
  }
  
  .tab-nav .tab-active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
  }
  