.timeline-container {
    position: relative;
    width: 100%;
    padding: 20px 0;
    margin: 20px auto;
  }
  
  .timeline-item {
    display: flex;
    position: relative;
    margin: 20px 0;
  }
  
  .timeline-item.left {
    justify-content: flex-end;
    padding-right: 15%;
  }
  
  .timeline-item.right {
    justify-content: flex-start;
    padding-left: 15%;
  }
  
  .timeline-content {
    border-radius: 6px;
    background-color: #f2f2f2;
    padding: 30px;
    text-align: center;
    width: 35%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.5);
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .timeline-content h2,
  .timeline-content h3 {
    margin: 0;
    font-size: 1.7em;
    color: #4e342e;
    margin-bottom: 0.5rem;
    text-shadow: 0.5px 0.5px #5d4037;
  }
  
  .timeline-content p {
    font-size: 1.4em;
  }
  
  .timeline-item .timeline-content:hover {
    transform: translateY(-5px); /* Slightly raise the box */
    box-shadow: 0 10px 20px rgba(0,0,0,0.2); /* Increase shadow for a 'lifted' effect */
  }

  /* Responsive adjustments */
  @media (max-width: 600px) {
    .timeline-item {
      flex-direction: column;
      align-items: center;
    }
  
    .timeline-item.left,
    .timeline-item.right {
      justify-content: center;
      padding: 0;
    }
  
    .timeline-content {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  